import { useForm } from 'react-hook-form'
import { TourProducts } from 'utils/TourProducts'
import { _validators } from '@redsales/ds/utils'
import { navigate } from 'gatsby'
import { useLoader } from 'hooks/useLoader'
import storage from 'utils/Storage'
import { useEffect } from 'react'
import makeTrackings from 'utils/make/trackings'
import { registerFormId } from 'utils/make/constants'

const useRegister = ({
  product,
  isEditing,
  onBackFromEdit,
  document: stateDocument,
}) => {
  const selectedProductPath = TourProducts[product] || '/cartoes'

  const { setLoader } = useLoader()
  const { control, handleSubmit, formState, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      document: '',
      name: '',
      email: '',
      phone: '',
    },
  })

  useEffect(() => {
    if (stateDocument) {
      setValue('document', stateDocument)
    }

    const userData = storage.session.getItem('user_data')

    if (userData) {
      setValue('document', userData.document)
      setValue('name', userData.name)
      setValue('email', userData.email)
      setValue('phone', userData.phone)
    }
  }, [])

  const validateFields = (data) => {
    let isValid = true

    if (!_validators.cpf(data.document) || !data.document) {
      setError('document', {
        type: 'manual',
        message: 'CPF inválido',
      })

      isValid = false
    }

    if (!_validators.email(data.email)) {
      setError('email', {
        type: 'manual',
        message: 'Email inválido',
      })

      isValid = false
    }

    if (!_validators.phone(data.phone) || !data.phone) {
      setError('phone', {
        type: 'manual',
        message: 'Celular inválido',
      })

      isValid = false
    }

    if (!_validators.name(data.name)) {
      setError('name', {
        type: 'manual',
        message: 'Nome inválido',
      })

      isValid = false
    }

    return isValid
  }

  const onSubmit = (data) => {
    setLoader({ isLoading: true, text: 'Estamos analisando seu cpf...' })
    const isValid = validateFields(data)

    if (!isValid) {
      setLoader({ isLoading: false })
      return
    }

    makeTrackings.formContinued({
      formId: registerFormId,
      formName: product,
      stepId: '93f7a638-dff1-41fa-ab2f-9ee0fb69e8a1',
      stepName: 'basic-data',
      stepNumber: 1,
    })

    if (!isEditing) {
      makeTrackings.formStarted({
        formId: registerFormId,
        formName: product,
        stepId: 'fcedd1d2-614c-4400-858a-4b171c3f9ec4',
        stepName: 'basic-data',
        stepNumber: 1,
      })

      makeTrackings.lead(data)
    }

    storage.session.setItem('user_data', data)

    setTimeout(() => {
      navigate(isEditing ? onBackFromEdit : selectedProductPath)
      setTimeout(() => {
        setLoader({ isLoading: false })
      }, 1000)
    }, 2000)
  }

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    formState,
    setError,
  }
}

export default useRegister
